import Vue from 'vue';

import money from './money';
import vatRate from './vatRate';
import portionSize from './portionSize';

Vue.filter('money', money);
Vue.filter('vatRate', vatRate);
Vue.filter('portionSize', portionSize);

export default {};
