import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        filters: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            active: null
        }
    },

    mutations: {
        setFirstNameFilter(state, firstName) {
            state.filters.firstName = firstName;
        },

        setLastNameFilter(state, lastName) {
            state.filters.lastName = lastName;
        },

        setEmailAddressFilter(state, emailAddress) {
            state.filters.emailAddress = emailAddress;
        },

        setActiveFilter(state, active) {
            state.filters.active = active;
        }
    },

    actions: {
        async getMany({commit}, {filters, pageNumber, perPage}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/parents`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getManyWithBalances({commit}, {filters, pageNumber, perPage}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/parents/withBalances`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, parentId) {
            const res = await axios.get(`${API_URL}/parents/${parentId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async create({commit}, parentData) {
            const res = await axios.post(`${API_URL}/parents`, parentData);

            if (res.status !== 201 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async update({commit}, {parentId, parentData}) {
            const res = await axios.put(`${API_URL}/parents/${parentId}`, parentData);

            if (res.status !== 200 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async remove({commit}, parentId) {
            const res = await axios.delete(`${API_URL}/parents/${parentId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async activate({commit}, parentId) {
            const res = await axios.post(`${API_URL}/parents/${parentId}/activate`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async deactivate({commit}, parentId) {
            const res = await axios.post(`${API_URL}/parents/${parentId}/deactivate`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async resetPassword({commit}, parentId) {
            const res = await axios.post(`${API_URL}/parents/${parentId}/resetPassword`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async changePassword({commit}, {parentId, changePasswordData}) {
            const res = await axios.put(`${API_URL}/parents/${parentId}/password`, changePasswordData);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async getChildren({commit}, {parentId, pageNumber}) {
            let q = {
                page: pageNumber
            };

            const res = await axios.get(`${API_URL}/parents/${parentId}/children`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getChild({commit}, {parentId, childId, withAutomarkedWeekdays}) {
            const params = {
                withAutomarkedWeekdays: withAutomarkedWeekdays ? 1 : 0
            };

            const res = await axios.get(`${API_URL}/parents/${parentId}/children/${childId}`, {params});

            if (res.status !== 200 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async createChild({commit}, {parentId, childData}) {
            const res = await axios.post(`${API_URL}/parents/${parentId}/children`, childData);

            if (res.status !== 201 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async updateChild({commit}, {parentId, childId, childData}) {
            const res = await axios.put(`${API_URL}/parents/${parentId}/children/${childId}`, childData);

            if (res.status !== 200 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async removeChild({commit}, {parentId, childId}) {
            const res = await axios.delete(`${API_URL}/parents/${parentId}/children/${childId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        }
    }
};

export default store;
