import i18n from 'obiado/lib/i18n';

export default () => {

    return [
        {
            title: i18n.t('Presences'),
            icon: 'fa fa-calendar-alt fa-fw',
            route: 'settings.tenant.presences'
        },
        // {
        //     title: i18n.t('Meals'),
        //     icon: 'fa fa-calendar-alt fa-fw',
        //     route: 'settings.tenant.meals'
        // }
    ];
};
