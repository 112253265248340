import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        filters: {
            name: ''
        }
    },

    mutations: {
        setNameFilter(state, name) {
            state.filters.name = name;
        }
    },

    actions: {
        async getMany({commit}, {filters, pageNumber, perPage, withClassrooms}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            if (withClassrooms) {
                q.withClassrooms = 1;
            }

            const res = await axios.get(`${API_URL}/institutions`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, institutionId) {
            const res = await axios.get(`${API_URL}/institutions/${institutionId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async create({commit}, institutionData) {
            const res = await axios.post(`${API_URL}/institutions`, institutionData);

            if (res.status !== 201 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async update({commit}, {institutionId, institutionData}) {
            const res = await axios.put(`${API_URL}/institutions/${institutionId}`, institutionData);

            if (res.status !== 200 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async remove({commit}, institutionId) {
            const res = await axios.delete(`${API_URL}/institutions/${institutionId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        }
    }
};

export default store;
