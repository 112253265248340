import axios from 'axios';
import {API_URL} from 'obiado/config';
import i18n from 'obiado/lib/i18n';
import download from 'downloadjs';

const store = {
    namespaced: true,

    actions: {
        async getStatistics({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/statistics`);

            if (!res.data || !res.data.statistics) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTodayPresencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/todayPresencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTodayAbsencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/todayAbsencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTomorrowPresencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/tomorrowPresencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTomorrowAbsencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/tomorrowAbsencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getDayMealsCounts({commit}, date) {
            const res = await axios.get(`${API_URL}/catering/dashboard/dayMealsCounts`, {params: {date}});

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getDayOrders({commit}, date) {
            const res = await axios.get(`${API_URL}/catering/dashboard/dayOrders`, {params: {date}});

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async exportKitchenSummary({commit}, date) {
            const res = await axios({
                url: `${API_URL}/catering/dashboard/kitchenSummary`,
                data: {date},
                method: 'POST',
                responseType: 'blob'
            });

            download(res.data, 'zestawienie_dla_kuchni_' + (new Date().toISOString().substr(0, 10)) + '.pdf', 'application/pdf');
        }
    }
};

export default store;
