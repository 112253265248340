import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Orders = () => import('./views/Orders');
const Order = () => import('./views/Order');
const OrderEdit = () => import('./views/OrderEdit');
const OrderPaid = () => import('./views/OrderPaid');

export default [
    {
        path: '/orders',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Orders')
        },

        children: [
            {
                path: '',
                name: 'orders.list',
                component: Orders,

                meta: {
                    label: i18n.t('Orders list')
                },

            },

            {
                path: 'create',
                name: 'orders.create',
                component: OrderEdit,

                meta: {
                    label: i18n.t('New order')
                },
            },

            {
                path: 'createDefault',
                name: 'orders.createDefault',
                component: OrderEdit,
            },

            {
                path: 'paid',
                name: 'orders.paid',
                component: OrderPaid
            },

            {
                path: ':orderId',
                name: 'orders.details',
                component: Order,

                meta: {
                    label: i18n.t('Order details')
                }
            },

            {
                path: ':orderId/edit',
                name: 'orders.edit',
                component: OrderEdit,

                meta: {
                    label: i18n.t('Edit order')
                }
            }
        ]
    }
];


