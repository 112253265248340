import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';
import download from 'downloadjs';

const store = {
    namespaced: true,

    state: {
        filters: {
            date: '',
            companyId: null,
            lastName: null,
            firstName: null
        }
    },

    mutations: {
        setDateFilter(state, date) {
            state.filters.date = date;
        },

        setCompanyIdFilter(state, companyId) {
            state.filters.companyId = companyId;
        },

        setLastNameFilter(state, lastName) {
            state.filters.lastName = lastName;
        },

        setFirstNameFilter(state, firstName) {
            state.filters.firstName = firstName;
        }
    },

    actions: {
        async getMany({commit}, {filters, pageNumber, perPage, withClassrooms}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/catering/orders`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, orderId) {
            const res = await axios.get(`${API_URL}/catering/orders/${orderId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async create({commit}, orderData) {
            const res = await axios.post(`${API_URL}/catering/orders`, orderData);

            if (res.status !== 201 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async createDefault({commit}, orderData) {
            const res = await axios.post(`${API_URL}/catering/orders/defaultMenus`, orderData);

            if (res.status !== 200 || !res.data) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async update({commit}, {orderId, orderData}) {
            const res = await axios.put(`${API_URL}/catering/orders/${orderId}`, orderData);

            if (res.status !== 200 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async remove({commit}, orderId) {
            const res = await axios.delete(`${API_URL}/catering/orders/${orderId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async markAsPaid({commit}, orderId) {
            const res = await axios.post(`${API_URL}/catering/orders/${orderId}/markAsPaid`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async markAsNotPaid({commit}, orderId) {
            const res = await axios.post(`${API_URL}/catering/orders/${orderId}/markAsNotPaid`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async markAsCancelled({commit}, orderId) {
            const res = await axios.post(`${API_URL}/catering/orders/${orderId}/markAsCancelled`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async cancel({commit}, orderId) {
            const res = await axios.post(`${API_URL}/catering/orders/${orderId}/cancel`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async pay({commit}, orderId) {
            const res = await axios.post(`${API_URL}/catering/orders/${orderId}/pay`);

            if (res.status !== 201 || !res.data.paymentType || !res.data.value) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async generateConfirmation({commit}, orderId) {
            const res = await axios({
                url: `${API_URL}/catering/orders/${orderId}/confirmation`,
                method: 'POST',
                responseType: 'blob'
            });

            download(res.data, 'potwierdzenie_zamowienia_' + orderId + '.pdf', 'application/pdf');
        }
    }
};

export default store;
