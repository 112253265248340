import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        filters: {
            name: ''
        }
    },

    mutations: {
        setNameFilter(state, name) {
            state.filters.name = name;
        }
    },

    actions: {
        async getMany({commit}, {filters, pageNumber, perPage}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/catering/menuTemplates`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, menuTemplateId) {
            const res = await axios.get(`${API_URL}/catering/menuTemplates/${menuTemplateId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async create({commit}, menuTemplateData) {
            const res = await axios.post(`${API_URL}/catering/menuTemplates`, menuTemplateData);

            if (res.status !== 201 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async update({commit}, {menuTemplateId, menuTemplateData}) {
            const res = await axios.put(`${API_URL}/catering/menuTemplates/${menuTemplateId}`, menuTemplateData);

            if (res.status !== 200 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async remove({commit}, menuTemplateId) {
            const res = await axios.delete(`${API_URL}/catering/menuTemplates/${menuTemplateId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        }
    }
};

export default store;
