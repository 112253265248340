import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const CompanyUsers = () => import('./views/CompanyUsers');
const CompanyUserEdit = () => import('./views/CompanyUserEdit');

export default [
    {
        path: '/companyUsers',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Users')
        },

        children: [
            {
                path: '',
                name: 'companyUsers.list',
                component: CompanyUsers,

                meta: {
                    label: i18n.t('Users list')
                }
            },

            {
                path: 'create',
                name: 'companyUsers.create',
                component: CompanyUserEdit,

                meta: {
                    label: i18n.t('New user')
                }
            },

            {
                path: ':companyUserId/edit',
                name: 'companyUsers.edit',
                component: CompanyUserEdit,

                meta: {
                    label: i18n.t('Edit user')
                }
            }
        ]
    }
];
