import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Children = () => import('./views/Children');

export default [
    {
        path: 'children',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Children')
        },

        children: [
            {
                path: '',
                name: 'children.list',
                component: Children
            }
        ]
    }
];
