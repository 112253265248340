import Vue from 'vue';
import Vuex from 'vuex';

import auth from 'obiado/modules/auth/store';
import calendar from 'obiado/modules/calendar/store';
import children from 'obiado/modules/children/store';
import classrooms from 'obiado/modules/classrooms/store';
import dashboard from 'obiado/modules/dashboard/store';
import diets from 'obiado/modules/diets/store';
import institutions from 'obiado/modules/institutions/store';
import meals from 'obiado/modules/meals/store';
import mealTypes from 'obiado/modules/mealTypes/store';
import mealSetGroups from 'obiado/modules/mealSetGroups/store';
import menus from 'obiado/modules/menus/store';
import menuTemplates from 'obiado/modules/menuTemplates/store';
import orders from 'obiado/modules/orders/store';
import parents from 'obiado/modules/parents/store';
import reports from 'obiado/modules/reports/store';
import cateringReports from 'obiado/modules/cateringReports/store';
import settings from 'obiado/modules/settings/store';
import terminal from 'obiado/modules/terminal/store';
import transactions from 'obiado/modules/transactions/store';
import companies from 'obiado/modules/companies/store';
import companyUsers from 'obiado/modules/companyUsers/store';

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: true, // TODO: Disable this on production
    modules: {
        auth,
        calendar,
        children,
        classrooms,
        dashboard,
        diets,
        institutions,
        meals,
        mealTypes,
        mealSetGroups,
        menus,
        menuTemplates,
        orders,
        parents,
        reports,
        cateringReports,
        settings,
        terminal,
        transactions,
        companies,
        companyUsers
    }
});

export default store;
