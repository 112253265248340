import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        filters: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            active: null
        }
    },

    mutations: {
        setFirstNameFilter(state, firstName) {
            state.filters.firstName = firstName;
        },

        setLastNameFilter(state, lastName) {
            state.filters.lastName = lastName;
        },

        setEmailAddressFilter(state, emailAddress) {
            state.filters.emailAddress = emailAddress;
        },

        setActiveFilter(state, active) {
            state.filters.active = active;
        }
    },

    actions: {
        async getManyForCompanyAdmin({commit}, {companyAdminId, pageNumber, formData}) {
            let q = {
                page: pageNumber,
                ...formData
            };

            const res = await axios.get(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getManyForCurrentCompanyAdmin({commit}, {pageNumber, formData}) {
            let q = {
                page: pageNumber,
                ...formData
            };

            const res = await axios.get(`${API_URL}/catering/companyAdmins/current/transactions`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, {companyAdminId, transactionId}) {
            const res = await axios.get(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions/${transactionId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getCompanyAdminBalance({commit}, companyAdminId) {
            const res = await axios.get(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions/balance`);

            if (!res.data || typeof res.data.amount !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getEndOfMonthCompanyAdminBalance({commit}, companyAdminId) {
            const res = await axios.get(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions/endOfMonthBalance`);

            if (!res.data || typeof res.data.amount !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getCurrentCompanyAdminBalance({commit}) {
            const res = await axios.get(`${API_URL}/catering/companyAdmins/current/balance`);

            if (!res.data || typeof res.data.amount !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getCurrentCompanyAdminEndOfMonthBalance({commit}) {
            const res = await axios.get(`${API_URL}/catering/companyAdmins/current/endOfMonthBalance`);

            if (!res.data || typeof res.data.amount !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async createForCompanyAdmin({commit}, {companyAdminId, transactionData}) {
            const res = await axios.post(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions`, transactionData);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async updateForCompanyAdmin({commit}, {companyAdminId, transactionId, transactionData}) {
            const res = await axios.put(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions/${transactionId}`, transactionData);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async removeForCompanyAdmin({commit}, {companyAdminId, transactionId}) {
            const res = await axios.delete(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions/${transactionId}`);

            if (!res.data || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async getCompanyAdminMonthlySummaries({commit}, companyAdminId) {
            const res = await axios.get(`${API_URL}/catering/companyAdmins/${companyAdminId}/transactions/monthlySummaries`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        }
    }
};

export default store;
