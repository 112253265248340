import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Institutions = () => import('./views/Institutions');
const InstitutionEdit = () => import('./views/InstitutionEdit');

export default [
    {
        path: '/institutions',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Institutions')
        },

        children: [
            {
                path: '',
                name: 'institutions.list',
                component: Institutions,

                meta: {
                    label: i18n.t('Institutions list')
                }
            },

            {
                path: 'create',
                name: 'institutions.create',
                component: InstitutionEdit,

                meta: {
                    label: i18n.t('New institution')
                }
            },

            {
                path: ':institutionId/edit',
                name: 'institutions.edit',
                component: InstitutionEdit,

                meta: {
                    label: i18n.t('Edit institution')
                }
            }
        ]
    }
];


