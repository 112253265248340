import i18n from 'obiado/lib/i18n';
import store from 'obiado/lib/store';
import schoolSideNavItems from "obiado/modules/settings/config/schoolSideNavItems";
import cateringSideNavItems from "obiado/modules/settings/config/cateringSideNavItems";

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const SettingsContainer = () => import('./components/SettingsContainer');
const PasswordSettings = () => import('./views/PasswordSettings');
const DeliveryAddressSettings = () => import('./views/DeliveryAddressSettings');
const CompanyDetailsSettings = () => import('./views/CompanyDetailsSettings');
const PhoneNumbersSettings = () => import('./views/PhoneNumbersSettings');
const TenantPresencesSettings = () => import('./views/TenantPresencesSettings');
const TenantFeesSettings = () => import('./views/TenantFeesSettings');
const TenantMealSettings = () => import('./views/TenantMealSettings');
const TenantOrdersSettings = () => import('./views/TenantOrdersSettings');
const DeliverySettings = () => import('./views/DeliverySettings');

const routes = [
    {
        path: '/settings',
        name: 'settings',

        redirect: to => {
            if (store.getters['auth/isCompanyAdmin']) {
                return {name: 'settings.account.phoneNumbers'};
            } else if (store.getters['auth/isTenantSchool']) {
                return {name: 'settings.tenant.presences'};
            } else if (store.getters['auth/isTenantCatering']) {
                return {name: 'settings.catering.fees'};
            } else {
                return {name: 'settings.account.password'};
            }
        },

        component: EmptyContainer,

        meta: {
            label: i18n.t('Settings')
        },

        children: [
            {
                path: 'account',
                name: 'settings.account',
                component: SettingsContainer,

                redirect: to => {
                    if (store.getters['auth/isCompanyAdmin']) {
                        return {name: 'settings.account.phoneNumbers'};
                    } else {
                        return {name: 'settings.account.password'};
                    }
                },

                props: {
                    title: i18n.t('Account settings'),
                    icon: 'fa fa-user'
                },

                meta: {
                    label: i18n.t('Account settings')
                },

                children: [
                    {
                        path: 'company',
                        name: 'settings.account.companyDetails',
                        component: CompanyDetailsSettings
                    },
                    {
                        path: 'profile',
                        name: 'settings.account.phoneNumbers',
                        component: PhoneNumbersSettings
                    },

                    {
                        path: 'password',
                        name: 'settings.account.password',
                        component: PasswordSettings
                    },

                    {
                        path: 'deliveryAddress',
                        name: 'settings.account.deliveryAddress',
                        component: DeliveryAddressSettings
                    }
                ]
            },

            {
                path: 'tenant',
                name: 'settings.tenant',
                component: SettingsContainer,

                redirect: () => {return {name: 'settings.tenant.presences'}},

                props: {
                    title: i18n.t('Tenant settings'),
                    icon: 'fa fa-building'
                },

                meta: {
                    label: i18n.t('Tenant settings')
                },

                children: [
                    {
                        path: 'presences',
                        name: 'settings.tenant.presences',
                        component: TenantPresencesSettings
                    },
                    // {
                    //     path: 'meals',
                    //     name: 'settings.tenant.meals',
                    //     component: TenantMealSettings
                    // },
                ]
            },

            {
                path: 'catering',
                name: 'settings.catering',
                component: SettingsContainer,

                redirect: () => {return {name: 'settings.catering.fees'}},

                props: {
                    title: i18n.t('Catering settings'),
                    icon: 'fa fa-building'
                },

                meta: {
                    label: i18n.t('Catering settings')
                },

                children: [
                    {
                        path: 'fees',
                        name: 'settings.catering.fees',
                        component: TenantFeesSettings
                    },

                    {
                        path: 'orders',
                        name: 'settings.catering.orders',
                        component: TenantOrdersSettings
                    },

                    {
                        path: 'delivery',
                        name: 'settings.catering.delivery',
                        component: DeliverySettings
                    }
                ]
            }
        ]
    }
];

export default routes;
