import i18n from 'obiado/lib/i18n';
import store from 'obiado/lib/store';

import calendarRoutes from 'obiado/modules/calendar/routes';
import childrenRoutes from 'obiado/modules/children/routes';
import classroomsRoutes from 'obiado/modules/classrooms/routes';
import dietsRoutes from 'obiado/modules/diets/routes';
import institutionsRoutes from 'obiado/modules/institutions/routes';
import mealTypesRoutes from 'obiado/modules/mealTypes/routes';
import mealSetGroupsRoutes from 'obiado/modules/mealSetGroups/routes';
import mealsRoutes from 'obiado/modules/meals/routes';
import menusRoutes from 'obiado/modules/menus/routes';
import menuTemplatesRoutes from 'obiado/modules/menuTemplates/routes';
import ordersRoutes from 'obiado/modules/orders/routes';
import parentsRoutes from 'obiado/modules/parents/routes';
import profileRoutes from 'obiado/modules/profile/routes';
import reportsRoutes from 'obiado/modules/reports/routes';
import settingsRoutes from 'obiado/modules/settings/routes';
import terminalRoutes from 'obiado/modules/terminal/routes';
import companiesRoutes from 'obiado/modules/companies/routes';
import companyUsersRoutes from 'obiado/modules/companyUsers/routes';

const DashboardLayout = () => import('./components/DashboardLayout');
const Dashboard = () => import('./views/Dashboard');

const routes = [
    {
        path: '/',
        redirect: '/orders',
        name: 'home',
        component: DashboardLayout,

        meta: {
            label: i18n.t('Dashboard'),
            authorized: true
        },

        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard
            }
        ]
    }
];

routes[0].children = routes[0].children.concat(
    calendarRoutes,
    childrenRoutes,
    classroomsRoutes,
    dietsRoutes,
    institutionsRoutes,
    mealTypesRoutes,
    mealSetGroupsRoutes,
    mealsRoutes,
    menusRoutes,
    menuTemplatesRoutes,
    ordersRoutes,
    parentsRoutes,
    profileRoutes,
    reportsRoutes,
    settingsRoutes,
    terminalRoutes,
    companiesRoutes,
    companyUsersRoutes
);

export default routes;
