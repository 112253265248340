import i18n from 'obiado/lib/i18n';

export default (value) => {
    if (!value) return i18n.n(0, 'currency');

    if (typeof value === 'object') {
        return i18n.n(value.amount, 'currency');
    }

    if (typeof value === 'number') {
        return i18n.n(value, 'currency');
    }

    return '';
};
