import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const MealSetGroups = () => import('./views/MealSetGroups');
const MealSetGroupEdit = () => import('./views/MealSetGroupEdit');

export default [
    {
        path: '/mealSetGroups',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Meal set groups')
        },

        children: [
            {
                path: '',
                name: 'mealSetGroups.list',
                component: MealSetGroups,

                meta: {
                    label: i18n.t('Meal set groups list')
                }
            },

            {
                path: 'create',
                name: 'mealSetGroups.create',
                component: MealSetGroupEdit,

                meta: {
                    label: i18n.t('New meal set group')
                }
            },

            {
                path: ':mealSetGroupId/edit',
                name: 'mealSetGroups.edit',
                component: MealSetGroupEdit,

                meta: {
                    label: i18n.t('Edit meal set group')
                }
            }
        ]
    }
];
