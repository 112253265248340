import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Parents = () => import('./views/Parents');
const ParentEdit = () => import('./views/ParentEdit');
const Parent = () => import('./views/Parent');
const ParentChildEdit = () => import('./views/ParentChildEdit');

export default [
    {
        path: '/parents',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Parents')
        },

        children: [
            {
                path: '',
                name: 'parents.list',
                component: Parents,

                meta: {
                    label: i18n.t('Parents list')
                }
            },

            {
                path: 'create',
                name: 'parents.create',
                component: ParentEdit,

                meta: {
                    label: i18n.t('New parent')
                }
            },

            {
                path: ':parentId',
                name: 'parents.details',
                component: Parent,

                meta: {
                    label: i18n.t('Parent details')
                }
            },

            {
                path: ':parentId/edit',
                name: 'parents.edit',
                component: ParentEdit,

                meta: {
                    label: i18n.t('Edit parent')
                }
            },

            {
                path: ':parentId/children/create',
                name: 'parents.children.create',
                component: ParentChildEdit,

                meta: {
                    label: i18n.t('New parent child')
                }
            },

            {
                path: ':parentId/children/:childId/edit',
                name: 'parents.children.edit',
                component: ParentChildEdit,

                meta: {
                    label: i18n.t('Edit parent child')
                }
            }
        ]
    }
];
