import axios from 'axios';
import {API_URL} from 'obiado/config';
import router from 'obiado/lib/router';
import i18n from 'obiado/lib/i18n';

if (localStorage.getItem('token')) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}

const store = {
    namespaced: true,

    state: {
        user: null,
        token: localStorage.getItem('token') || null
    },

    getters: {
        user: state => state.user,
        isAuthenticated: state => !!state.token,
        isCook: state => !!state.token && state.user && state.user.roles.includes('ROLE_COOK'),
        isParent: state => !!state.token && state.user && state.user.roles.includes('ROLE_PARENT'),
        isAdmin: state => !!state.token && state.user && state.user.roles.includes('ROLE_ADMIN'),
        isSchoolWorker: state => !!state.token && state.user && state.user.roles.includes('ROLE_SCHOOL'),
        isCateringWorker: state => !!state.token && state.user && state.user.roles.includes('ROLE_CATERING'),
        isTenantSchool: state => !!state.token && state.user && state.user.type == 'school',
        isTenantCatering: state => !!state.token && state.user && state.user.type == 'catering',
        isCompanyAdmin: state => !!state.token && state.user && state.user.type == 'companyAdmin',
        isEmployee: state => !!state.token && state.user && state.user.type == 'employee'
    },

    mutations: {
        login(state, token) {
            state.token = token;
        },

        logout(state) {
            state.token = null;
            state.user = null;
        },

        setCurrentUser(state, user) {
            state.user = user;
        }
    },

    actions: {
        async login({commit, dispatch}, credentials) {
            const res = await axios.post(`${API_URL}/auth/login`, credentials);

            if (!res.data.token) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            localStorage.setItem('token', res.data.token);

            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;

            await dispatch('fetchCurrentUser');

            commit('login', res.data.token);

            return true;
        },

        logout({commit}) {
            localStorage.removeItem('token');

            delete axios.defaults.headers.common['Authorization'];

            commit('logout');

            router.push({name: 'auth.login'});
        },

        async fetchCurrentUser({commit}) {
            const res = await axios.get(`${API_URL}/auth/currentUser`);

            if (!res.data || !res.data.id) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            commit('setCurrentUser', res.data);

            return true;
        },

        async sendPasswordResetToken({commit}, emailAddress) {
            const res = await axios.post(`${API_URL}/auth/sendPasswordResetToken`, {emailAddress});

            if (!res.data || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async resetPassword({commit}, data) {
            const res = await axios.post(`${API_URL}/auth/resetPassword/${data.token}`, data.form);

            if (!res.data || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async changePassword({commit}, passwords) {
            const res = await axios.put(`${API_URL}/account/password`, passwords);

            if (!res.data || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async updatePhoneNumbers({commit}, phoneNumbersData) {
            const res = await axios.put(`${API_URL}/account/phoneNumbers`, phoneNumbersData);

            if (!res.data || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async updateCompanyDetails({commit}, companyDetailsData) {
            const res = await axios.put(`${API_URL}/account/companyDetails`, companyDetailsData);

            if (!res.data || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async updateDeliveryAddress({commit}, deliveryAddressData) {
            const res = await axios.put(`${API_URL}/account/deliveryAddress`, deliveryAddressData);

            if (!res.data || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        }
    }
};

export default store;
