import Vue from 'vue';
import Router from 'vue-router';
import store from 'obiado/lib/store';

import dashboardRoutes from 'obiado/modules/dashboard/routes';
import authRoutes from 'obiado/modules/auth/routes';

Vue.use(Router);

const routes = [
    {
        path: '*',
        redirect: '/dashboard'
    }
];

const router = new Router({
    mode: 'history',
    linkActiveClass: 'open active',
    scrollBehavior: (to, from, savedPosition) => {
        if (to.name === 'calendar.presences.child' || to.name.includes('transactions.parent')) {
            return savedPosition;
        } else if (to.name === 'calendar.presences' && from.name === 'calendar.presences.child') {
            return savedPosition;
        } else if (to.name.includes('transactions') && from.name.includes('transactions.parent')) {
            return savedPosition;
        }

        return {y: 0};
    },

    routes: routes.concat(dashboardRoutes, authRoutes)
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authorized) && !store.getters['auth/isAuthenticated']) {
        next({name: 'auth.login'});
    } else if (to.matched.some(record => record.meta.guest) && store.getters['auth/isAuthenticated']) {
        if (store.getters['auth/isCook']) {
            next({name: 'terminal'});
        } else if (store.getters['auth/isParent']) {
            next({name: 'parent.presences'});
        } else if (store.getters['auth/isCompanyAdmin']) {
            next({name: 'orders.list'});
        } else {
            next({name: 'dashboard'});
        }
    }

    next();
});

export default router;
