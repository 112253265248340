import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const MealTypes = () => import('./views/MealTypes');
const MealTypeEdit = () => import('./views/MealTypeEdit');

export default [
    {
        path: '/mealTypes',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Meal types')
        },

        children: [
            {
                path: '',
                name: 'mealTypes.list',
                component: MealTypes,

                meta: {
                    label: i18n.t('Meal types list')
                }
            },

            {
                path: 'create',
                name: 'mealTypes.create',
                component: MealTypeEdit,

                meta: {
                    label: i18n.t('New meal type')
                }
            },

            {
                path: ':mealTypeId/edit',
                name: 'mealTypes.edit',
                component: MealTypeEdit,

                meta: {
                    label: i18n.t('Edit meal type')
                }
            }
        ]
    }
];
