import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        freeDays: []
    },

    getters: {
        freeDays: state => state.freeDays
    },

    mutations: {
        setFreeDays(state, freeDays) {
            state.freeDays = freeDays;
        }
    },

    actions: {
        async getFreeDays({commit}, institutionId) {
            const res = await axios.get(`${API_URL}/calendar/freeDays`, {params: {institutionId}});

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async createFreeDays({commit}, days) {
            const res = await axios.post(`${API_URL}/calendar/freeDays`, days);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async createFreeDaysFromRange({commit}, freeDaysRangeData) {
            const res = await axios.post(`${API_URL}/calendar/freeDays/range`, freeDaysRangeData);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async removeFreeDays({commit}, days) {
            const res = await axios.delete(`${API_URL}/calendar/freeDays`, {data: days});

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getChildDays({commit}, childId) {
            const res = await axios.get(`${API_URL}/calendar/childDays/${childId}`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async markPresences({commit}, {childId, days}) {
            const res = await axios.post(`${API_URL}/calendar/childDays/${childId}/presences`, days);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async markAbsences({commit}, {childId, days}) {
            const res = await axios.post(`${API_URL}/calendar/childDays/${childId}/absences`, days);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        }
    }
};

export default store;
