<template>
    <div>
        <router-view></router-view>

        <notifications :duration=5000 :max=5></notifications>
    </div>
</template>

<script>
    export default {
        name: 'App'
    }
</script>

<style lang="scss">
    $fa-font-path: '~@fortawesome/fontawesome-free/fonts/';
    @import '~@fortawesome/fontawesome-free/css/all.min.css';

    @import '~bootstrap-vue/dist/bootstrap-vue.min.css';

    @import 'assets/scss/app';
</style>
