import i18n from 'obiado/lib/i18n';

const Terminal = () => import('./views/Terminal');

export default [
    {
        path: '/terminal',
        name: 'terminal',
        component: Terminal,

        meta: {
            label: i18n.t('Terminal')
        }
    }
];
