import axios from 'axios';
import download from 'downloadjs';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    actions: {
        async orders({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/catering/reports/orders`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_zamowien_' + reportData.dateFrom + '_' + reportData.dateTo + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async ordersList({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/catering/reports/ordersList`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_zamowien_' + reportData.dateFrom + '_' + reportData.dateTo + '.pdf', 'application/pdf');
        },

        async customersAmounts({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/catering/reports/customersAmounts`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'wartosci_zamowien_' + reportData.date + '.pdf', 'application/pdf');
        }
    }
};

export default store;
