import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pl from 'obiado/translations/pl.json'

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'pl',

    messages: {
        'pl': pl
    },

    numberFormats: {
        'pl': {
            currency: {
                style: 'currency',
                currency: 'PLN',
                currencyDisplay: 'code'
            }
        }
    }
});

export default i18n;
