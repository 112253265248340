import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Companies = () => import('./views/Companies');
const CompanyEdit = () => import('./views/CompanyEdit');

export default [
    {
        path: '/companies',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Companies')
        },

        children: [
            {
                path: '',
                name: 'companies.list',
                component: Companies,

                meta: {
                    label: i18n.t('Companies list')
                }
            },

            {
                path: 'create',
                name: 'companies.create',
                component: CompanyEdit,

                meta: {
                    label: i18n.t('New company')
                }
            },

            {
                path: ':companyId/edit',
                name: 'companies.edit',
                component: CompanyEdit,

                meta: {
                    label: i18n.t('Edit company')
                }
            }
        ]
    }
];


