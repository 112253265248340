import i18n from 'obiado/lib/i18n';

const Profile = () => import('./views/Profile');

export default [
    {
        path: 'profile',
        component: Profile,

        meta: {
            label: i18n.t('Profile')
        }
    }
];
