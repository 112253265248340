import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const MenuTemplates = () => import('./views/MenuTemplates');
const MenuTemplateEdit = () => import('./views/MenuTemplateEdit');

export default [
    {
        path: '/menuTemplates',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Menu templates')
        },

        children: [
            {
                path: '',
                name: 'menuTemplates.list',
                component: MenuTemplates,

                meta: {
                    label: i18n.t('Menu templates list')
                }
            },

            {
                path: 'create',
                name: 'menuTemplates.create',
                component: MenuTemplateEdit,

                meta: {
                    label: i18n.t('New menu template')
                }
            },

            {
                path: ':menuTemplateId/edit',
                name: 'menuTemplates.edit',
                component: MenuTemplateEdit,

                meta: {
                    label: i18n.t('Edit menu template')
                }
            }
        ]
    }
];
