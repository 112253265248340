const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');

const Login = () => import('./views/Login');
const ForgotPassword = () => import('./views/ForgotPassword');
const ResetPassword = () => import('./views/ResetPassword');

export default [
    {
        path: '/auth',
        name: 'auth',
        component: EmptyContainer,

        meta: {
            guest: true
        },

        children: [
            {
                path: 'login',
                name: 'auth.login',
                component: Login
            },

            {
                path: 'forgotPassword',
                name: 'auth.forgotPassword',
                component: ForgotPassword
            },

            {
                path: 'resetPassword/:token',
                name: 'auth.resetPassword',
                component: ResetPassword
            }
        ]
    }
];
