import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Diets = () => import('./views/Diets');
const DietEdit = () => import('./views/DietEdit');

export default [
    {
        path: '/diets',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Diets')
        },

        children: [
            {
                path: '',
                name: 'diets.list',
                component: Diets,

                meta: {
                    label: i18n.t('Diets list')
                }
            },

            {
                path: 'create',
                name: 'diets.create',
                component: DietEdit,

                meta: {
                    label: i18n.t('New diet')
                }
            },

            {
                path: ':dietId/edit',
                name: 'diets.edit',
                component: DietEdit,

                meta: {
                    label: i18n.t('Edit diet')
                }
            }
        ]
    }
];
