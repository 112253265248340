import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        filters: {
            name: '',
            mealTypeId: null,
            mealSetGroupId: null
        }
    },

    mutations: {
        setNameFilter(state, name) {
            state.filters.name = name;
        },

        setMealTypeIdFilter(state, mealTypeId) {
            state.filters.mealTypeId = mealTypeId;
        },

        setMealSetGroupIdFilter(state, mealSetGroupId) {
            state.filters.mealSetGroupId = mealSetGroupId;
        }
    },

    actions: {
        async getMany({commit}, {filters, pageNumber, perPage}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/catering/meals`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, mealId) {
            const res = await axios.get(`${API_URL}/catering/meals/${mealId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async create({commit}, mealData) {
            const res = await axios.post(`${API_URL}/catering/meals`, mealData);

            if (res.status !== 201 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async update({commit}, {mealId, mealData}) {
            const res = await axios.put(`${API_URL}/catering/meals/${mealId}`, mealData);

            if (res.status !== 200) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async remove({commit}, mealId) {
            const res = await axios.delete(`${API_URL}/catering/meals/${mealId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async getAvailableMeals({commit}, {companyId, date, pageNumber, perPage}) {
            let q = {
                date,
                page: pageNumber
            };

            if (companyId) {
                q.companyId = companyId;
            }

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/catering/meals/availableMeals`, {params: q});

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'))
            }

            return res.data;
        }
    }
};

export default store;
