import i18n from 'obiado/lib/i18n';

const Reports = () => import('./views/Reports');
const PresencesList = () => import('./views/PresencesList');
const AbsencesList = () => import('./views/AbsencesList');
const ReportPresences = () => import('./views/ReportPresences');
const ReportDailyMeals = () => import('./views/ReportDailyMeals');
const ReportPeriodMeals = () => import('./views/ReportPeriodMeals');
const ReportMealsSummary = () => import('./views/ReportMealsSummary');
const ReportParentsBalances = () => import('./views/ReportParentsBalances');
const ReportPayments = () => import('./views/ReportPayments');
const ExportChildren = () => import('./views/ExportChildren');
const ExportParents = () => import('./views/ExportParents');

const CateringOrdersReport = () => import('obiado/modules/cateringReports/views/OrdersReport');
const CateringOrdersListReport = () => import('obiado/modules/cateringReports/views/OrdersListReport');
const CateringCustomersAmountsReport = () => import('obiado/modules/cateringReports/views/CustomersAmountsReport');

export default [
    {
        path: 'reports',
        name: 'reports',
        component: Reports,

        meta: {
            label: i18n.t('Reports')
        },

        children: [
            {
                path: 'presencesList',
                name: 'reports.presencesList',
                component: PresencesList,

                meta: {
                    label: i18n.t('Presences list')
                }
            },

            {
                path: 'absencesList',
                name: 'reports.absencesList',
                component: AbsencesList,

                meta: {
                    label: i18n.t('Absences list')
                }
            },

            {
                path: 'presences',
                name: 'reports.presences',
                component: ReportPresences,

                meta: {
                    label: i18n.t('Presences report')
                }
            },

            {
                path: 'dailyMeals',
                name: 'reports.dailyMeals',
                component: ReportDailyMeals,

                meta: {
                    label: i18n.t('Daily meals report')
                }
            },

            {
                path: 'periodMeals',
                name: 'reports.periodMeals',
                component: ReportPeriodMeals,

                meta: {
                    label: i18n.t('Period meals report')
                }
            },

            {
                path: 'mealsSummary',
                name: 'reports.mealsSummary',
                component: ReportMealsSummary,

                meta: {
                    label: i18n.t('Meals summary report')
                }
            },

            {
                path: 'parentsBalances',
                name: 'reports.parentsBalances',
                component: ReportParentsBalances,

                meta: {
                    label: i18n.t('Parents balances report')
                }
            },

            {
                path: 'payments',
                name: 'reports.payments',
                component: ReportPayments,

                meta: {
                    label: i18n.t('Payments report')
                }
            },

            {
                path: 'childrenExport',
                name: 'reports.childrenExport',
                component: ExportChildren,

                meta: {
                    label: i18n.t('Children export')
                }
            },

            {
                path: 'parentsExport',
                name: 'reports.parentsExport',
                component: ExportParents,

                meta: {
                    label: i18n.t('Parents export')
                }
            },

            {
                path: 'orders',
                name: 'reports.orders',
                component: CateringOrdersReport,

                meta: {
                    label: i18n.t('Orders report')
                }
            },

            {
                path: 'ordersList',
                name: 'reports.ordersList',
                component: CateringOrdersListReport,

                meta: {
                    label: i18n.t('Orders PDF report')
                }
            },

            {
                path: 'customersAmounts',
                name: 'reports.customersAmounts',
                component: CateringCustomersAmountsReport,

                meta: {
                    label: i18n.t('Orders amounts PDF report')
                }
            }
        ]
    }
];
