import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    actions: {
        async getTodayEntries({commit}) {
            const res = await axios.get(`${API_URL}/terminal`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async scanCard({commit}, cardNumber) {
            const res = await axios.post(`${API_URL}/terminal/${cardNumber}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        }
    }
};

export default store;
