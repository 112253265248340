import i18n from 'obiado/lib/i18n';

export default () => {

    return [
        {
            title: i18n.t('Fees'),
            icon: 'fa fa-calendar-alt fa-fw',
            route: 'settings.catering.fees'
        },

        {
            title: i18n.t('Orders'),
            icon: 'fa fa-box fa-fw',
            route: 'settings.catering.orders'
        },

        {
            title: i18n.t('Delivery'),
            icon: 'fa fa-truck fa-fw',
            route: 'settings.catering.delivery'
        }
    ];
};
