import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        filters: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            companyUserType: null,
            companyId: null,
            active: null
        }
    },

    mutations: {
        setFirstNameFilter(state, firstName) {
            state.filters.firstName = firstName;
        },

        setLastNameFilter(state, lastName) {
            state.filters.lastName = lastName;
        },

        setEmailAddressFilter(state, emailAddress) {
            state.filters.emailAddress = emailAddress;
        },

        setCompanyUserTypeFilter(state, companyUserType) {
            state.filters.companyUserType = companyUserType;
        },

        setCompanyIdFilter(state, companyId) {
            state.filters.companyId = companyId;
        },

        setActiveFilter(state, active) {
            state.filters.active = active;
        }
    },

    actions: {
        async getMany({commit}, {filters, pageNumber, perPage}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/catering/companyUsers`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, companyUserId) {
            const res = await axios.get(`${API_URL}/catering/companyUsers/${companyUserId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async create({commit}, companyUserData) {
            const res = await axios.post(`${API_URL}/catering/companyUsers`, companyUserData);

            if (res.status !== 201 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async update({commit}, {companyUserId, companyUserData}) {
            const res = await axios.put(`${API_URL}/catering/companyUsers/${companyUserId}`, companyUserData);

            if (res.status !== 200 || !res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async remove({commit}, companyUserId) {
            const res = await axios.delete(`${API_URL}/catering/companyUsers/${companyUserId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async activate({commit}, companyUserId) {
            const res = await axios.post(`${API_URL}/catering/companyUsers/${companyUserId}/activate`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async deactivate({commit}, companyUserId) {
            const res = await axios.post(`${API_URL}/catering/companyUsers/${companyUserId}/deactivate`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async resetPassword({commit}, companyUserId) {
            const res = await axios.post(`${API_URL}/catering/companyUsers/${companyUserId}/resetPassword`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async changePassword({commit}, {companyUserId, changePasswordData}) {
            const res = await axios.put(`${API_URL}/catering/companyUsers/${companyUserId}/password`, changePasswordData);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async changeCompanyUserType({commit}, {companyUserId, companyUserType}) {
            const res = await axios.post(`${API_URL}/catering/companyUsers/${companyUserId}/changeType`, {type: companyUserType});

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        }
    }
};

export default store;
