import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    actions: {
        async getTenantPresencesMarkingConfig({commit}) {
            const res = await axios.get(`${API_URL}/settings/presencesMarkingConfig`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async updateTenantPresencesMarkingConfig({commit}, presencesMarkingData) {
            const res = await axios.put(`${API_URL}/settings/presencesMarkingConfig`, presencesMarkingData);

            if (!res.data || res.data.status !== true) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTenantCateringOrdersConfig({commit}) {
            const res = await axios.get(`${API_URL}/catering/settings/ordersConfig`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async updateTenantCateringOrdersConfig({commit}, ordersData) {
            const res = await axios.put(`${API_URL}/catering/settings/ordersConfig`, ordersData);

            if (!res.data || res.data.status !== true) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTenantFeesConfig({commit}) {
            const res = await axios.get(`${API_URL}/catering/settings/fees`);

            if (!res.data || typeof res.data !== 'object' || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async updateTenantFeesConfig({commit}, feesData) {
            const res = await axios.put(`${API_URL}/catering/settings/fees`, feesData);

            if (!res.data || typeof res.data !== 'object' || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTenantMealsConfig({commit}) {
            const res = await axios.get(`${API_URL}/settings/mealsConfig`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async updateTenantMealsConfig({commit}, mealsData) {
            const res = await axios.put(`${API_URL}/settings/mealsConfig`, mealsData);

            if (!res.data || res.data.status !== true) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTenantPaymentMethodsConfig({commit}) {
            const res = await axios.get(`${API_URL}/catering/settings/paymentMethodsConfig`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getPostCodeDeliveryCosts({commit}, {filters, pageNumber}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            const res = await axios.get(`${API_URL}/catering/delivery`, {params: q});

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getPostCodeDeliveryCost({commit}, postCodeDeliveryCostId) {
            const res = await axios.get(`${API_URL}/catering/delivery/${postCodeDeliveryCostId}`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async createPostCodeDeliveryCost({commit}, postCodeDeliveryCostData) {
            const res = await axios.post(`${API_URL}/catering/delivery`, postCodeDeliveryCostData);

            if (!res.data || typeof res.data !== 'object' || res.status !== 201) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async updatePostCodeDeliveryCost({commit}, {postCodeDeliveryCostId, postCodeDeliveryCostData}) {
            const res = await axios.put(`${API_URL}/catering/delivery/${postCodeDeliveryCostId}`, postCodeDeliveryCostData);

            if (!res.data || typeof res.data !== 'object' || res.status !== 200) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async removePostCodeDeliveryCost({commit}, postCodeDeliveryCostId) {
            const res = await axios.delete(`${API_URL}/catering/delivery/${postCodeDeliveryCostId}`);

            if (!res.data || typeof res.data !== 'object' || res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async getDeliveryCost({commit}, postCode) {
            try {
                const res = await axios.get(`${API_URL}/catering/delivery/${postCode}`);

                if (!res.data || res.status !== 200 || typeof res.data !== 'object') {
                    return null;
                }

                return res.data;
            } catch (e) {
                return null;
            }
        }
    }
};

export default store;
